import React from 'react'
// import authRoute from './authRoute'
import { APP_PREFIX_PATH } from 'constants/route.constant'
import { ADMIN, USER } from 'constants/roles.constant'
import LandingPageTwo from 'pages/LandingPageTwo'
import LandingPageThree from 'pages/LandingPageThree'
import ProductsList from 'views/Products/ProductsList'
import StoreConnect from 'views/integrations/StoreConnect'
import FullfillmentService from 'views/services/FullfillmentService'
import WhyUSPF from 'views/services/WhyUSPF'
import USPFSolutions from 'views/solutions'


export const productsroutes = [
    {
        path: "",
        element: <LandingPageTwo />,
    },
    {
        path: "uspf-team",
        element: <LandingPageThree />,
    },
    {
        path: "products",
        element: <ProductsList />,
    },
    {
        path: "products/:category/:id",
        element: <ProductsList />,
    },
    {
        path: "connect/:store",
        element: <StoreConnect />,
    },
    {
        path: "fulfillment-services",
        element: <FullfillmentService />,
    },
    {
        path: "our-solutions",
        element: <USPFSolutions />,
    },
    
]